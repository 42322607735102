import React, { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { WalletInfo } from 'data';
import { useAppDispatch, useAuth } from 'hooks';
import { walletModalActions } from 'store/wallet-modal';
import { toast } from 'react-toastify';

const StyledBox = styled(Box)<{ disabled: boolean }>`
	width: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	margin-right: 20px;
	cursor: ${(props) => (!props.disabled ? 'pointer' : 'initial')};
	border-radius: 8px;
	filter: ${(props) =>
		props.disabled ? 'blur(1px) grayscale(100%)' : 'none'};

	&:hover {
		background-color: ${(props) => (props.disabled ? 'none' : '#4d4d60')};
	}
`;

type Props = WalletInfo;

const WalletTile: React.ComponentType<Props> = ({
	name,
	disabled,
	image,
	connector,
}) => {
	const { login } = useAuth();
	const dispatch = useAppDispatch();

	const onWalletSelected = useCallback(() => {
		if (disabled) return;

		const hostname = window.location.hostname;
		if (hostname.includes('dashboard2')) {
			toast('Wallet connection is not allowed yet.', {
				type: 'info',
			});
			return;
		}

		login(connector, 'Avalanche');
		dispatch(walletModalActions.closeWalletModal());
	}, [connector, disabled, dispatch, login]);

	return (
		<StyledBox disabled={disabled} onClick={onWalletSelected}>
			<img src={image} height={60} />
			<Box
				sx={{
					height: 10,
				}}
			/>
			<Typography
				fontSize="0.8rem"
				textAlign="center"
				color="white"
				gutterBottom
			>
				{name}
			</Typography>
		</StyledBox>
	);
};

export { WalletTile };
