import React, { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import { useWeb3React } from '@web3-react/core';

import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

import { useAppDispatch } from 'hooks';

import { walletModalActions } from 'store/wallet-modal';

const MainButton = styled(Button)`
	${css`
		background-color: transparent;
		color: white;
		border: 2px solid #235ee1;
		padding: 6px 16px;
		border-radius: 4px;
	`}
`;

const DisconnectedView: React.ComponentType = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<img src="/icons/wallet.png" height={20} />
			<Box sx={{ width: 8 }} />
			<Typography
				sx={{
					fontWeight: 'bold',
				}}
			>
				Connect
			</Typography>
		</Box>
	);
};

const ConnectedView: React.ComponentType = () => {
	const { account } = useWeb3React();

	let activeAccount = account || '';
	activeAccount = activeAccount.slice(0, 6) + '...' + activeAccount.slice(-4);

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<img src="/generic-avatar.svg" height={20} />
			<Box sx={{ width: 8 }} />
			<Typography
				sx={{
					fontWeight: 'bold',
				}}
			>
				{activeAccount}
			</Typography>
		</Box>
	);
};

const ConnectionButton: React.ComponentType = () => {
	const { account } = useWeb3React();
	const dispatch = useAppDispatch();

	const onConnectionButtonClick = useCallback(() => {
		if (!account) dispatch(walletModalActions.openWalletModal());
	}, [account, dispatch]);

	return (
		<div>
			<MainButton onClick={onConnectionButtonClick}>
				{account ? <ConnectedView /> : <DisconnectedView />}
			</MainButton>
		</div>
	);
};

export { ConnectionButton };
