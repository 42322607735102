import { call, put, fork, take } from 'redux-saga/effects';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Web3State } from 'types';
import { whitelistActions } from 'store/whitelist/index';
import axios from 'axios';

import env from 'config/env';
const { ABI_BASE_URL } = env;

function* fetchWhitelist({ library }: Web3State) {
	const signer = library.getSigner();
	const message = 'GET whitelist user';

	try {
		const signature: string = yield call(async () => {
			return await signer.signMessage(message);
		});

		const whitelist: string[] = yield call(async () => {
			const { data } = await axios.get(
				`${ABI_BASE_URL}/api/admin/user-whitelist?signature=${signature}&message=${message}`,
			);

			return data;
		});

		yield put(whitelistActions.whitelistFetched(whitelist));
	} catch (error) {
		yield put(whitelistActions.whitelistFetchFailed());
	}
}

export default function* saga() {
	while (true) {
		const action: PayloadAction<Web3State> = yield take([
			whitelistActions.fetchWhitelist,
		]);

		yield fork(fetchWhitelist, action.payload);
	}
}
