import type { ExternalProvider } from '@ethersproject/providers';
import { ChainNames, NetworkConfig, networkConfig } from 'config';

export const setupNetwork = async (
	chain: ChainNames,
	externalProvider?: ExternalProvider,
) => {
	const provider = externalProvider || window.ethereum;

	if (provider) {
		const { chainId, chainName, nativeCurrency, rpcUrls } = networkConfig[
			chain
		] as NetworkConfig;

		try {
			const chainConfig = {
				chainName,
				nativeCurrency,
				rpcUrls,
				chainId: `0x${chainId.toString(16)}`,
			};

			if (provider.request) {
				await provider.request({
					method: 'wallet_addEthereumChain',
					params: [chainConfig],
				});
			}

			return true;
		} catch (error) {
			// TODO: Handle error
			return false;
		}
	} else {
		// TODO: failed to setup network because wallet not found
		return false;
	}
};
