import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Card, TextField } from '@mui/material';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { whitelistActions } from 'store/whitelist';
import { useAppDispatch } from 'hooks';
import { toast } from 'react-toastify';

import env from 'config/env';
const { ABI_BASE_URL } = env;

const AddToWhitelist = () => {
	const dispatch = useAppDispatch();
	const { account, library } = useWeb3React();
	const [address, setAddress] = useState<string>('');

	const isAddressValid = useMemo(() => {
		return ethers.utils.isAddress(address);
	}, [address]);

	const attemptAddToWhitelist = useCallback(
		async (newAddr: string) => {
			if (!isAddressValid || !account || !library) return;

			const signer = library.getSigner();

			try {
				const signature = await signer.signMessage(address);

				await axios.post(`${ABI_BASE_URL}/api/admin/user-whitelist`, {
					signature,
					userAddr: newAddr,
				});

				toast('Added to whitelist', {
					type: 'success',
				});

				dispatch(whitelistActions.fetchWhitelist({ account, library }));
			} catch (error) {
				toast(
					// @ts-ignore
					error?.response?.data?.errors?.[0]?.message ||
						'Something went wrong',
					{
						type: 'error',
					},
				);
			} finally {
				setAddress('');
			}
		},
		[isAddressValid, account, library, dispatch],
	);

	return (
		<Card sx={{ p: 2 }} elevation={2}>
			<Box>
				<TextField
					value={address}
					fullWidth
					label="New address"
					variant="outlined"
					onChange={(event) => setAddress(event.target.value)}
				/>
				<Box height={10} />
				<Button
					onClick={() => attemptAddToWhitelist(address)}
					disabled={!isAddressValid}
					sx={{
						height: 48,
					}}
					variant="contained"
					fullWidth
				>
					Add to whitelist
				</Button>
			</Box>
		</Card>
	);
};

export { AddToWhitelist };
