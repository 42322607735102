import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { whitelistActions } from 'store/whitelist';
import { useWeb3React } from '@web3-react/core';
import { Box, Card, Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CachedIcon from '@mui/icons-material/Cached';
import axios from 'axios';
import { toast } from 'react-toastify';

import env from 'config/env';
const { ABI_BASE_URL } = env;

const Whitelist = () => {
	const dispatch = useAppDispatch();
	const { account, library } = useWeb3React();

	const fetching = useAppSelector((state) => state.whitelist.fetching);
	const whitelist = useAppSelector((state) => state.whitelist.whitelist);

	const attemptRemoveAddress = useCallback(
		async (address: string) => {
			if (!account || !library) {
				return;
			}

			try {
				const signer = library.getSigner();
				const signature = await signer.signMessage(address);

				await axios.delete(
					`${ABI_BASE_URL}/api/admin/user-whitelist?userAddr=${address}&signature=${signature}`,
				);

				toast('Removed from whitelist', {
					type: 'success',
				});

				dispatch(whitelistActions.fetchWhitelist({ account, library }));
			} catch (error) {
				toast(
					// @ts-ignore
					error?.response?.data?.errors?.[0]?.message ||
						'Something went wrong',
					{
						type: 'error',
					},
				);
			}
		},
		[account, library],
	);

	return (
		<Card sx={{ p: 2 }} elevation={2}>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<h3>Whitelist</h3>

				<IconButton
					disabled={fetching}
					onClick={() => {
						dispatch(
							whitelistActions.fetchWhitelist({
								account,
								library,
							}),
						);
					}}
				>
					<CachedIcon />
				</IconButton>
			</Box>

			{!whitelist.length && (
				<div>
					<Typography>No addresses in whitelist</Typography>
				</div>
			)}

			{whitelist.map((white, index) => (
				<Box
					key={index}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography>{white}</Typography>
					<IconButton onClick={() => attemptRemoveAddress(white)}>
						<CloseIcon />
					</IconButton>
				</Box>
			))}
		</Card>
	);
};

export { Whitelist };
