import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { Web3ReactProvider } from '@web3-react/core';

import DApp from './DApp';

import theme from 'theme';
import { store } from 'store';
import { getLibrary } from 'utils/web3React';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
	<Web3ReactProvider getLibrary={getLibrary}>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<DApp />
			</ThemeProvider>
		</Provider>
		<ToastContainer />
	</Web3ReactProvider>,
);
