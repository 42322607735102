export type ChainNames = 'Local' | 'Avalanche Fuji' | 'Avalanche';

export enum ConnectorNames {
	Injected = 'injected',
	WalletConnect = 'walletconnect',
	WalletLink = 'walletlink',
	Core = 'core',
}

export type NetworkConfig = {
	label: string;
	image: string;
	isTestChain: boolean;
	chainId: number;
	chainName: string;
	nativeCurrency: {
		name: string;
		symbol: string;
		decimals: number;
	};
	rpcUrls: string[];
};

export const networkConfig: Record<ChainNames, any> = {
	Local: {
		label: 'Local',
		image: '/networks/ethereum.svg',
		isTestChain: true,
		chainId: 56,
		chainName: 'Ethereum',
		nativeCurrency: {
			name: 'ETH',
			symbol: 'eth',
			decimals: 18,
		},
		rpcUrls: [],
	},
	'Avalanche Fuji': {
		label: 'Avalanche Fuji',
		image: '/networks/avalanche.svg',
		isTestChain: true,
		chainId: 43113,
		chainName: 'Avalanche Fuji Testnet',
		nativeCurrency: {
			name: 'AVAX',
			symbol: 'AVAX',
			decimals: 18,
		},
		rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
		blockExplorerUrls: ['https://cchain.explorer.avax-test.network'],
	},
	Avalanche: {
		label: 'Avalanche',
		image: '/networks/avalanche.svg',
		isTestChain: false,
		chainId: 43114,
		chainName: 'Avalanche Mainnet',
		nativeCurrency: {
			name: 'AVAX',
			symbol: 'AVAX',
			decimals: 18,
		},
		rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
		blockExplorerUrls: ['https://cchain.explorer.avax.network/'],
	},
};
