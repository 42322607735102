import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { ChainNames, ConnectorNames } from 'config';
import { connectorByName } from 'utils/web3React';
import {
	NoEthereumProviderError,
	UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
	WalletConnectConnector,
	UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
} from '@web3-react/walletconnect-connector';
import { setupNetwork } from 'utils/wallet';
import { toast } from 'react-toastify';

const useAuth = () => {
	const { activate, deactivate } = useWeb3React();

	const login = useCallback(
		async (connectorId: ConnectorNames, network: ChainNames) => {
			localStorage.setItem('connectorId', connectorId);
			localStorage.setItem('networkId', network);

			const connectorOrGetConnector = connectorByName(
				connectorId,
				network,
			);

			const connector =
				typeof connectorOrGetConnector !== 'function'
					? connectorOrGetConnector
					: await connectorOrGetConnector();

			if (typeof connector !== 'function' && connector) {
				activate(connector, async (error: Error) => {
					if (error instanceof UnsupportedChainIdError) {
						const provider = await connector.getProvider();
						const hasSetup = await setupNetwork(network, provider);

						// NOTE: we can't add or switch local network
						// Metamask doesn't allow it
						// WalletConnect has no such functionality
						if (hasSetup) {
							activate(connector);
						} else {
							// if the connection could not be established, that means that we tried
							// adding/connecting local network
							// we need to clear the session config stored earlier

							localStorage.removeItem('connectorId');
							localStorage.removeItem('networkId');
						}
					} else {
						// TODO: clear the local storage
						localStorage.removeItem('connectorId');
						localStorage.removeItem('networkId');

						if (error instanceof NoEthereumProviderError) {
							toast('No wallet found', {
								type: 'error',
							});
						} else if (
							error instanceof UserRejectedRequestErrorInjected ||
							error instanceof
								UserRejectedRequestErrorWalletConnect
						) {
							if (connector instanceof WalletConnectConnector) {
								connector.walletConnectProvider = null;
							}

							toast('Please allow access your account', {
								type: 'error',
							});
						} else {
							toast(
								error.message ||
									'Unknown error. Please try again.',
								{
									type: 'error',
								},
							);
							console.log(error);
						}
					}
				});
			} else {
				toast('Unable to connect to wallet', {
					type: 'error',
				});
			}
		},
		[activate],
	);

	const logout = useCallback(() => {
		deactivate();
	}, [deactivate]);

	return { login, logout };
};

export { useAuth };
