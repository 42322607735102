import { useEffect } from 'react';
import { useAuth } from 'hooks';
import { ChainNames, ConnectorNames } from 'config';

const useEagerConnect = () => {
	const { login } = useAuth();

	useEffect(() => {
		const tryLogin = (c: ConnectorNames, networkId: ChainNames) => {
			setTimeout(() => login(c, networkId));
		};

		const connectorId = localStorage.getItem(
			'connectorId',
		) as ConnectorNames;
		const networkId = localStorage.getItem('networkId') as ChainNames;

		if (!connectorId || !networkId) return;

		tryLogin(connectorId, networkId);
	}, [login]);
};

export { useEagerConnect };
