import React, { useEffect } from 'react';
import { Whitelist, AddToWhitelist } from 'screens/Landing/components';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import { useAppDispatch } from 'hooks';
import { whitelistActions } from 'store/whitelist';

const Grid = styled(Box)`
	display: grid;
	grid-template-columns: 500px 300px;
	grid-gap: 10px;
`;

const Landing = () => {
	const dispatch = useAppDispatch();
	const { account, library } = useWeb3React();

	useEffect(() => {
		if (account && library)
			dispatch(whitelistActions.fetchWhitelist({ account, library }));
	}, [account, library, dispatch]);

	return (
		<Box display="flex" justifyContent="center" marginTop={4}>
			<Grid>
				<Whitelist />
				<AddToWhitelist />
			</Grid>
		</Box>
	);
};

export { Landing };
