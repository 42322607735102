import * as React from 'react';
import { useEagerConnect } from 'hooks';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Navbar } from 'components';
import { Landing } from 'screens';

const DApp: React.ComponentType = () => {
	useEagerConnect();

	return (
		<Router>
			<div>
				<Navbar />

				<Switch>
					<Route path="/">
						<Landing />
					</Route>
				</Switch>
			</div>
		</Router>
	);
};

export default DApp;
