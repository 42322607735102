import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { Web3State } from 'types';

export type WhitelistState = {
	fetching: boolean;
	updating: boolean;
	whitelist: string[];
};

const initialState: WhitelistState = {
	fetching: false,
	updating: false,
	whitelist: [],
};

export const whitelistSlice = createSlice({
	name: 'whitelist',
	initialState,
	reducers: {
		fetchWhitelist: (
			state: Draft<WhitelistState>,
			_: PayloadAction<Web3State>,
		) => {
			state.fetching = true;
		},
		whitelistFetched: (
			draft,
			action: PayloadAction<WhitelistState['whitelist']>,
		) => {
			draft.fetching = false;
			draft.whitelist = action.payload;
		},
		whitelistFetchFailed: (state: Draft<WhitelistState>) => {
			state.fetching = false;
			state.whitelist = [];
		},
	},
});

export const whitelistActions = whitelistSlice.actions;
const whitelistReducer = whitelistSlice.reducer;

export default whitelistReducer;
