import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import walletModalReducer from 'store/wallet-modal';
import whitelistReducer from 'store/whitelist';

import IndexSaga from './Index-Saga';

const rootReducer = combineReducers({
	walletModal: walletModalReducer,
	whitelist: whitelistReducer,
});

const sagaMiddleware = createSagaMiddleware();

// attach saga middleware to redux store
const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(sagaMiddleware),
});

sagaMiddleware.run(IndexSaga);

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export { store };
export type { RootState, AppDispatch };
