import { ethers } from 'ethers';
import { InjectedConnector } from '@web3-react/injected-connector';
import {
	ChainNames,
	ConnectorNames,
	NetworkConfig,
	networkConfig,
} from 'config';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

const POLLING_INTERVAL = 12000;

export const connectorByName = (
	connectorName: ConnectorNames,
	chain: ChainNames,
) => {
	const network = networkConfig[chain] as NetworkConfig;
	const chainId = network.chainId;

	switch (connectorName) {
		case ConnectorNames.Injected:
		case ConnectorNames.Core:
			return new InjectedConnector({ supportedChainIds: [chainId] });
		case ConnectorNames.WalletConnect:
			return new WalletConnectConnector({
				rpc: { [chainId]: network.rpcUrls[0] },
				bridge: 'https://bridge.walletconnect.org',
				qrcode: true,
				pollingInterval: POLLING_INTERVAL,
			});
		case ConnectorNames.WalletLink:
			return async () => {
				const { WalletLinkConnector } = await import(
					'@web3-react/walletlink-connector'
				);

				return new WalletLinkConnector({
					url: 'https://staging.vcred.trade',
					appName: 'VCRED',
					appLogoUrl:
						'https://staging.vcred.trade/brand/logo-full_on-light.svg',
					supportedChainIds: [network.chainId],
				});
			};
	}
};

export const getLibrary = (provider: any): ethers.providers.Web3Provider => {
	const library = new ethers.providers.Web3Provider(provider);
	library.pollingInterval = POLLING_INTERVAL;
	return library;
};
