import { createSlice, Draft } from '@reduxjs/toolkit';

export type WalletModalState = {
	isOpen: boolean;
};

const initialState: WalletModalState = {
	isOpen: false,
};

export const walletModalSlice = createSlice({
	name: 'wallet-modal',
	initialState,
	reducers: {
		openWalletModal: (state: Draft<WalletModalState>) => {
			state.isOpen = true;
		},
		closeWalletModal: (state: Draft<WalletModalState>) => {
			state.isOpen = false;
		},
	},
});

export const walletModalActions = walletModalSlice.actions;
const walletModalReducer = walletModalSlice.reducer;

export default walletModalReducer;
