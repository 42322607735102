import { ConnectorNames } from 'config';

export type WalletInfo = {
	name: string;
	connector: ConnectorNames;
	image: string;
	disabled: boolean;
};

const wallets: WalletInfo[] = [
	{
		name: 'Metamask',
		connector: ConnectorNames.Injected,
		image: '/wallets/metamask.svg',
		disabled: false,
	},
	{
		name: 'Core',
		connector: ConnectorNames.Core,
		image: '/wallets/core.svg',
		disabled: true,
	},
	{
		name: 'Wallet Connect',
		connector: ConnectorNames.WalletConnect,
		image: '/wallets/wallet-connect.svg',
		disabled: true,
	},
	{
		name: 'Coinbase',
		connector: ConnectorNames.WalletLink,
		image: '/wallets/coinbase.svg',
		disabled: true,
	},
];

export { wallets };
